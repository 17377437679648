/*eslint-env node*/
const ensureString = (envVariable?: string) => {
    return envVariable || '';
};

/* Build information */
export const country = ensureString(import.meta.env.VITE_COUNTRY);
export const language = ensureString(import.meta.env.VITE_LANGUAGE);
export const env = ensureString(
    import.meta.env.VITE_ENV || import.meta.env.VITE_NODE_ENV
);
export const version = (import.meta.env.VITE_VERSION || 'unknown')
    .split('/')
    .pop() as string;
export const publicUrl = ensureString(import.meta.env.VITE_PUBLIC_URL);
export const shoppingCartUrl = `${import.meta.env.VITE_LANDING_PAGE_DOMAIN}/${import.meta.env.VITE_COUNTRY}/${
    import.meta.env.VITE_LANGUAGE
}/shoppingcart/`;
export const businessCustomerCartUrl = `${import.meta.env.VITE_LANDING_PAGE_DOMAIN}/${import.meta.env.VITE_COUNTRY}/${
    import.meta.env.VITE_LANGUAGE
}/business-store/cart`;
export const loginUrl = `${import.meta.env.VITE_LANDING_PAGE_DOMAIN}/${import.meta.env.VITE_COUNTRY}/${
    import.meta.env.VITE_LANGUAGE
}/profile/login/?from=${window.location.origin + window.location.pathname}&isCartLogin=true`;
export const pipUrl = `${import.meta.env.VITE_LANDING_PAGE_DOMAIN}/${import.meta.env.VITE_COUNTRY}/${import.meta.env.VITE_LANGUAGE}/p/-`;

/* Endpoints and credentials */
export const cartEndpoint = ensureString(import.meta.env.VITE_CART_ENDPOINT);

export const optimizelyKey = ensureString(
    import.meta.env.VITE_OPTIMIZELY_SDK_KEY
);

export const optimizelyKeySecondary = ensureString(
    import.meta.env.VITE_OPTIMIZELY_SDK_KEY_SECONDARY
);

/* Sentry */
const sentryDsn = ensureString(import.meta.env.VITE_SENTRY_DSN);

export const [, sentryKey = ''] = sentryDsn.match(/:\/\/(.*?)@/) || [];
export const [, sentryProject = ''] =
    sentryDsn.match(/@.*sentry.io\/(.*)/) || [];
export const sentryClient = 'cart-agent';

// agent path will be something like /se/sv/shoppingcart/agent
export const agentPath = '.*/shoppingcart/agent/.*';

export const allowCookies = () =>
    window.ikea?.cookieConsent ? window.ikea.cookieConsent.hasConsent(2) : true; // Allow cookies for markets that don't have ikea.cookieConsent

export const environment: 'staging' | 'production' =
    env === 'production' ? 'production' : 'staging';

const isProduction = environment === 'production';

export const orderCaptureClientId = isProduction
    ? '6a38e438-0bbb-4d4f-bc55-eb314c2e8e23'
    : '038c2273-957e-45fe-9fb7-2b90f1caab48';
export const orderCaptureEndpoint = isProduction
    ? 'https://ordercapture.ingka.com/ordercaptureapi'
    : 'https://ordercapture.ingkadt.com/ordercaptureapi';
export const orderCaptureRedirectUrl = isProduction
    ? 'https://www.ikea.com/{locale}/order/continue/?checkoutId={resourceId}'
    : 'https://www.cte.ikeadt.com/{locale}/order/continue/?checkoutId={resourceId}';

export const COUNTRIES_WITH_BUSINESS_CART_URL = [
    'us',
    'es',
    'jp',
    'de',
    'au',
    'se',
    'gb',
    'ie',
    'no',
];
