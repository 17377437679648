import type { OptimizelyWrapper } from '../types.ts';
import { optimizelyKey, optimizelyKeySecondary } from './settings.ts';

let optimizely: OptimizelyWrapper | null = null;
let optimizelySecondary: OptimizelyWrapper | null = null;

export const initOptimizely = async () => {
    try {
        if (optimizelyKey === '') {
            return;
        }
        const queryParameters = new URLSearchParams(window.location.search);
        const userId = queryParameters.get('opti_user_id');
        const options = {
            userId,
        };
        const attributes = {
            userId,
        };
        optimizely = await window.ikea.experiment.init(
            optimizelyKey,
            options,
            attributes
        );
    } catch (e) {
        reportError(e as Error);
    }
};

// Secondary wrapper for cross web experiments
export const initOptimizelySecondary = async () => {
    try {
        if (optimizelyKeySecondary === '') {
            return;
        }
        const queryParameters = new URLSearchParams(window.location.search);
        const userId = queryParameters.get('opti_user_id');
        const options = {
            userId,
        };
        const attributes = {
            userId,
        };
        optimizelySecondary = await window.ikea.experiment.init(
            optimizelyKeySecondary,
            options,
            attributes
        );
    } catch (e) {
        reportError(e as Error);
    }
};

export { optimizely, optimizelySecondary };
