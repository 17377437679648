import type {
    EventTags,
    OptimizelyDecision,
    UserAttributes,
} from '@optimizely/optimizely-sdk';

type AnalyticsAttributes = {
    source: string;
    ga?: {
        custom: unknown;
    };
};

export type CartEvent = IkeaEvent<CartPayload>;
export type UpdateDataEvent = IkeaEvent<UpdateDataPayload>;
export type UpdateCartCountEvent = IkeaEvent<UpdateCartCountPayload>;
export type RemoveFromCartEvent = IkeaEvent<RemoveFromCartPayload>;
export type UpdateQuantityEvent = IkeaEvent<UpdateQuantityPayload>;

enum UnitType {
    Area = 'AREA',
    Meterware = 'METERWARE',
    Multipack = 'MULTIPACK',
    Piece = 'PIECE',
    Volume = 'VOLUME',
    Weight = 'WEIGHT',
    Weightware = 'WEIGHTWARE',
}
type UnitValue = {
    unit?: string | null;
    value?: string | null;
};

export type DisplayUnit = {
    type?: UnitType | null;
    imperial?: UnitValue;
    metric?: UnitValue;
};

export type Measurement = {
    imperial?: string;
    metric?: string;
};

export type CartAgentItem = {
    itemNo: string;
    itemType: 'ART' | 'SPR' | null;
    quantity: number;
    product: {
        name: string;
        images: Array<{
            url: string;
            quality: string;
            width: string;
        }>;
        unitCode?: string;
        category?: string;
        description?: string;
        displayUnit?: DisplayUnit;
        measurements?: Array<Measurement>;
        formattedItemNo?: string;
    };
    maxQuantity: number;
    displayPrice?: number;
    previousPrice: number;
    isFamilyItem: boolean;
    pricePerItem: number;
    pricePerItemGA: number;
    priceValidTo: string;
    lowestPreviousPrice: number;
    priceOfferType?: string;
};

export type MinimalCartItem = {
    itemNo: string;
    name: string;
    images: Array<{
        url: string;
        quality: string;
        width: string;
    }>;
    quantity: number;
    type: 'ART' | 'SPR' | null;
    pricePerItemGA: number;
};

export type CartData = {
    cart_id?: string;
    cartItems: Array<MinimalCartItem>;
    packageWeight?: string;
    regularTotalPrice: {
        amount: number;
    };
};

export type CartAgentPlanner = {
    code?: string;
    name?: string;
    quantity: number;
    displayPrice?: number;
    displayImage?: string;
    items: Array<{
        itemNo: string;
        quantity: number;
        pricePerItemGA: number;
        product: {
            name: string;
            unitCode: 'METER' | 'PIECE';
        };
    }>;
    pricePerItemGA: number;
    deepLink: string;
};

export type CartAgentType = {
    cart_id?: string;
    items?: Array<CartAgentItem>;
    planners: Array<CartAgentPlanner>;
    coupon?: { code: string };
    employeeDiscountApplied?: boolean;
    quantity: number;
    regularTotalPrice: {
        amount: number;
    };
    packageWeight?: string;
};

export type CartErrorItem = {
    itemNo: string;
    description: string;
};

export type Item = {
    productNumber: string;
    productName: string;
    productType: string;
    quantity: string;
    source: string;
    currency?: string;
    productPrice?: string;
    ratings?: number;
    plannerCode?: string;
};

export type CartPayload = {
    items?: Array<Item>;
    ga: {
        custom: {
            [key: string]: unknown;
        };
        params: {
            items: Array<unknown>;
        };
    };
    source?: string;
    ignoreGa?: boolean;
    addPartial?: boolean;
    planners?: Array<string>;
    skipToast?: boolean;
} & (
    | { items: Array<Item>; planners?: Array<string> }
    | { items?: Array<Item>; planners: Array<string> }
);

export type UpdateDataPayload = {
    data: {
        cartId: string;
        items: Array<{
            itemNo: string;
            quantity: number;
            type: 'ART' | 'SPR';
            isFamilyItem: boolean;
            product: {
                name: string;
                images: Array<{ url: string; quality: string; width: number }>;
            };
            regularPrice: {
                unit: {
                    inclTax?: number | null;
                };
            };
        }>;
        packageWeight?: string;
        planners: Array<{
            quantity: number;
            name: string;
            code: string;
            images: Array<string | null>;
            deepLink: string;
        }>;
        regularTotalPrice?: {
            totalInclDiscount: { inclTax?: number | null };
        };
    };
    source: 'cart';
    store: string;
};

export type RemoveFromCartPayload = {
    productNumber: string;
    productName: string;
    type: 'PLANNER' | 'ITEM';
} & AnalyticsAttributes;

export type UpdateQuantityPayload = {
    productNumber: string;
    quantity: number;
} & AnalyticsAttributes;

export type UpdateCartCountPayload = {
    source: string;
    quantity?: number;
};

export interface IkeaEvent<P> {
    body: P;
    event: string;
    namespace: string;
    time: number;
}

// export type Callback<P> = (payload: P, event: IkeaEvent<P>) => any;

// Optimizely
type OptimizelyWrapper = {
    instance: unknown;
    track: (event: string, attributes?: unknown, tags?: EventTags) => void;
    activate: (experimentName: string) => string;
    isFeatureEnabled: (featureName: string) => boolean;
    decide: (key: string, attributes?: unknown) => unknown;
    getVariation: (experimentName: string) => string;
    activates: (experiments: Array<string>) => Array<[string, string]>;
};

export type {
    EventTags,
    OptimizelyDecision,
    OptimizelyWrapper,
    UserAttributes,
};
// End Optimizely

export type PhraseTranslations = typeof import('./translations/en-XZ.json');

export type PhraseKey = keyof PhraseTranslations;

// Analytics
export type GoogleAnalyticsEventObj = {
    name?: string;
    version?: string;
    event_category?: string;
    event_action?: string;
    event_label?: string;
    page_path?: string;
    page_location?: string;
    custom?: {
        [key: string]: unknown;
    };
    params?: {
        [key: string]: unknown;
    };
};

export type CustomCartEvent = {
    items?: Array<AnalyticsItem>;
    custom: {
        cart_id: string;
    };
    ga?: {
        custom?: {
            [index: string]: unknown;
        };
        params?: {
            [key: string]: unknown;
        };
    };
    source?: string;
};

export type AnalyticsItem = {
    id: string;
    price?: string;
    quantity: string;
    ratings?: number;
    name?: string;
    plannerCode?: string;
};
// End analytics

// Postal Code
export type PostalCodeEvent = {
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    city?: string;
    postalCode: string;
    state?: string;
    countryCode?: string;
    source: string;
    method?: string;
};

export type PostalCodeValidateResult = {
    isValid: boolean;
    formattedZip: string;
    invalidReason: string;
};

// End of Postal Code

export enum Locale {
    CaES = 'ca-ES',
    CsCZ = 'cs-CZ',
    DaDK = 'da-DK',
    DeAT = 'de-AT',
    DeCH = 'de-CH',
    DeDE = 'de-DE',
    EnAT = 'en-AT',
    EnAU = 'en-AU',
    EnBE = 'en-BE',
    EnCA = 'en-CA',
    EnCH = 'en-CH',
    EnCZ = 'en-CZ',
    EnDE = 'en-DE',
    EnES = 'en-ES',
    EnFI = 'en-FI',
    EnGB = 'en-GB',
    EnIE = 'en-IE',
    EnIN = 'en-IN',
    EnJP = 'en-JP',
    EnKR = 'en-KR',
    EnNL = 'en-NL',
    EnNO = 'en-NO',
    EnPT = 'en-PT',
    EnSE = 'en-SE',
    EnUS = 'en-US',
    EsES = 'es-ES',
    EsUS = 'es-US',
    EuES = 'eu-ES',
    FiFI = 'fi-FI',
    FrBE = 'fr-BE',
    FrCA = 'fr-CA',
    FrCH = 'fr-CH',
    FrFR = 'fr-FR',
    GlES = 'gl-ES',
    HrHR = 'hr-HR',
    HuHU = 'hu-HU',
    ItCH = 'it-CH',
    ItIT = 'it-IT',
    JaJP = 'ja-JP',
    KoKR = 'ko-KR',
    NlBE = 'nl-BE',
    NlNL = 'nl-NL',
    NoNO = 'no-NO',
    PlPL = 'pl-PL',
    PtPT = 'pt-PT',
    RoRO = 'ro-RO',
    SkSK = 'sk-SK',
    SlSI = 'sl-SI',
    SrRS = 'sr-RS',
    SvSE = 'sv-SE',
}

export type SettingsDClass = {
    price: {
        currencyCode: string;
    };
    locale: Locale;
};

export interface AnalyticsEventOptions {
    event_action?: string;
    event_label?: string;
    event_category?: string;
    name?: string;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    custom?: any;
    params?: {
        currency?: string;
        items?: ParamsItems;
    };
    non_interaction?: boolean;
}

export type ParamsItems = Array<{
    id: string;
    price: number;
    quantity: number;
    name?: string;
}>;

export type AnalyticsEvent = {
    category?: string;
    action?: string;
    label?: string;
    name?: string;
    custom?: {
        cart_id?: string;
        cart_value?: number;
        cart_value_local?: number;
        service_cart_value?: number;
        cart_quantity?: number;
        services_available?: 'N' | 'Y';
        removal_services_available?: 'N' | 'Y';
        postal_code?: string;
        receiving_solution?: string;
        error_id?: string;
        error_type?: string;
        error_text?: string;
        assembly_associated_item?: string;
        item_number_event?: string;
    };
    params?: {
        items?: ParamsItems;
        coupon?: string;
    };
    non_interaction?: boolean;
    persistentEcommerceParams?: { items?: ParamsItems };
    address?: unknown;
};
